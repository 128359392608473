export const themes = {
  //
  // FABOURITES
  //

  chromatix: {
    background: '#021C27',
    text: '#ffffff',
    primary: '#f7277a',
    group: 'favourites',
  },
  plex: {
    background: '#021C27',
    text: '#ffffff',
    primary: '#e5a00d',
    group: 'favourites',
  },
  // spotify: {
  //   background: '#111111',
  //   text: '#ffffff',
  //   primary: '#1ed760',
  //   group: 'favourites',
  // },

  //
  // DARK
  //

  'black-pink': {
    background: '#111111',
    text: '#ffffff',
    primary: '#f7277a',
    group: 'dark',
  },
  'black-red': {
    background: '#111111',
    text: '#ffffff',
    primary: '#ff0000',
    group: 'dark',
  },
  'black-orange': {
    background: '#111111',
    text: '#ffffff',
    primary: '#ff6f10',
    group: 'dark',
  },
  'black-yellow': {
    background: '#111111',
    text: '#ffffff',
    primary: '#e5a00d',
    group: 'dark',
  },
  'black-green-1': {
    background: '#111111',
    text: '#ffffff',
    primary: '#9de50d',
    group: 'dark',
  },
  'black-green-2': {
    background: '#111111',
    text: '#ffffff',
    primary: '#1ed760',
    group: 'dark',
  },
  'black-teal': {
    background: '#111111',
    text: '#ffffff',
    primary: '#109dad',
    group: 'dark',
  },
  'black-blue-1': {
    background: '#111111',
    text: '#ffffff',
    primary: '#169cfc',
    group: 'dark',
  },
  'black-blue-2': {
    background: '#111111',
    text: '#ffffff',
    primary: '#3768f0',
    group: 'dark',
  },
  'black-indigo': {
    background: '#111111',
    text: '#ffffff',
    primary: '#7e5aff',
    group: 'dark',
  },
  'black-purple': {
    background: '#111111',
    text: '#ffffff',
    primary: '#ab5dff',
    group: 'dark',
  },

  //
  // LIGHT
  //

  'white-pink': {
    background: '#ffffff',
    text: '#111111',
    primary: '#f7277a',
    group: 'light',
  },
  'white-red': {
    background: '#ffffff',
    text: '#111111',
    primary: '#e50000',
    group: 'light',
  },
  'white-orange': {
    background: '#ffffff',
    text: '#111111',
    primary: '#ed670d',
    group: 'light',
  },
  'white-yellow': {
    background: '#ffffff',
    text: '#111111',
    primary: '#aa9000',
    group: 'light',
  },
  'white-green-1': {
    background: '#ffffff',
    text: '#111111',
    primary: '#7fbb09',
    group: 'light',
  },
  'white-green-2': {
    background: '#ffffff',
    text: '#111111',
    primary: '#10993e',
    group: 'light',
  },
  'white-teal': {
    background: '#ffffff',
    text: '#111111',
    primary: '#0b7b87',
    group: 'light',
  },
  'white-blue-1': {
    background: '#ffffff',
    text: '#111111',
    primary: '#127fce',
    group: 'light',
  },
  'white-blue-2': {
    background: '#ffffff',
    text: '#111111',
    primary: '#345ed0',
    group: 'light',
  },
  'white-indigo': {
    background: '#ffffff',
    text: '#111111',
    primary: '#6b4cda',
    group: 'light',
  },
  'white-purple': {
    background: '#ffffff',
    text: '#111111',
    primary: '#8748ca',
    group: 'light',
  },
};
