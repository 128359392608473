// ======================================================================
// IMPORTS
// ======================================================================

import style from './TitleSection.module.scss';

// ======================================================================
// COMPONENT
// ======================================================================

const TitleSection = ({ title }) => {
  return <div className={style.wrap}>{title && <h3 className={style.title}>{title}</h3>}</div>;
};

// ======================================================================
// EXPORT
// ======================================================================

export default TitleSection;
