// ======================================================================
// IMPORTS
// ======================================================================

import { SettingsChangelog, TitleHeading } from 'js/components';

// ======================================================================
// COMPONENT
// ======================================================================

const Component = () => {
  return (
    <>
      <TitleHeading title="Changelog" />
      <SettingsChangelog />
    </>
  );
};

// ======================================================================
// EXPORT
// ======================================================================

export default Component;
