// ======================================================================
// IMPORTS
// ======================================================================

import { TitleHeading } from 'js/components';

// ======================================================================
// COMPONENT
// ======================================================================

const Error404Auth = () => (
  <main>
    <TitleHeading title="404 Not Found" />
  </main>
);

// ======================================================================
// EXPORT
// ======================================================================

export default Error404Auth;
