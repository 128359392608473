// ======================================================================
// IMPORTS
// ======================================================================

import { SettingsAppearance, TitleHeading } from 'js/components';

// ======================================================================
// COMPONENT
// ======================================================================

const Component = () => {
  return (
    <>
      <TitleHeading title="Appearance" />
      <SettingsAppearance />
    </>
  );
};

// ======================================================================
// EXPORT
// ======================================================================

export default Component;
