// ======================================================================
// IMPORTS
// ======================================================================

import { Queue } from 'js/components';

import style from './RightBar.module.scss';

// ======================================================================
// COMPONENT
// ======================================================================

const RightBar = () => {
  return (
    <div className={style.wrap}>
      <Queue />
    </div>
  );
};

// ======================================================================
// EXPORT
// ======================================================================

export default RightBar;
