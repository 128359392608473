// ======================================================================
// IMPORTS
// ======================================================================

import style from './FilterWrap.module.scss';

// ======================================================================
// RENDER
// ======================================================================

export const FilterWrap = ({ children }) => {
  return <div className={style.wrap}>{children}</div>;
};

// ======================================================================
// EXPORT
// ======================================================================

export default FilterWrap;
