// ======================================================================
// IMPORTS
// ======================================================================

import style from './TitleBasic.module.scss';

// ======================================================================
// COMPONENT
// ======================================================================

const TitleBasic = ({ title }) => {
  return <div className={style.wrap}>{title && <h1 className={style.title}>{title}</h1>}</div>;
};

// ======================================================================
// EXPORT
// ======================================================================

export default TitleBasic;
